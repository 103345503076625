
export function initLoadMore() {

        $('.dotsreveal').on("click", function (e) {
            
            e.preventDefault();
            var $this = $(this);
            var id = $this.data('select');
            var dots = $('.dotscontainer[data-select="'+id+'"]');
            var moreText = $('.dotsmore[data-select="'+id+'"]');
            var hide = $('.dotshide[data-select="'+id+'"]');

            moreText[0].style.display = "inline";
            dots[0].style.display = "none";
            $this[0].style.display = "none";
            hide[0].style.display = "inline";

        });

        $('.dotshide').on("click", function (e) {
            e.preventDefault();
            var $this = $(this);
            var id = $this.data('select');
            var dots = $('.dotscontainer[data-select="'+id+'"]');
            var moreText = $('.dotsmore[data-select="'+id+'"]');
            var hide = $('.dotsreveal[data-select="'+id+'"]');

            moreText[0].style.display = "none";
            dots[0].style.display = "inline";
            $this[0].style.display = "none";
            hide[0].style.display = "inline";

    });


}
