export function initContact() {
	jQuery.validator.setDefaults({
		highlight: function(element, errorClass, validClass) {
			jQuery(element).parent().addClass(errorClass).removeClass(validClass);
		},
		unhighlight: function(element, errorClass, validClass) {
			jQuery(element).parent().removeClass(errorClass).addClass(validClass);
		},
		// debug: true,
	});

	jQuery("#gform_1").validate({
		rules: {
			'input_14.3': 'required', // First name 
			'input_14.6': 'required', // Last name,
			'input_11': 'required', // Company
			'input_3': 'required', // Email
			'input_5': 'required' // Region
		}
	});
	jQuery("#gform_8").validate({
		rules: {
			'input_1.3': 'required', // First name 
			'input_1.6': 'required', // Last name,
			'input_3': 'required', // Company
			'input_2': 'required', // Email
			'input_7': 'required' // Region
		}
	});
	jQuery("#gform_9").validate({
		rules: {
			'input_1.3': 'required', // First name 
			'input_1.6': 'required', // Last name,
			'input_3': 'required', // Company
			'input_2': 'required', // Email
			'input_7': 'required' // Region
		}
	});

	jQuery('#input_1_5').on('change', function() {
		// Custom select work around
		jQuery("#gform_1").validate().element('#input_1_5');
	});
	jQuery('#input_9_7').on('change', function() {
		// Custom select work around
		jQuery("#gform_9").validate().element('#input_9_7');
	});

   let selects = document.querySelectorAll('.gfield_select');
	for (let index = 0; index < selects.length; index++) {
		const element = selects[index];
		element.setAttribute('tabindex', '-1');
		NiceSelect.bind(element, {searchable: false});
	}

	let enquiry_hidden_ids = [];

   for (const [key, id] of Object.entries(gforms)) {
      if (id) {
         jQuery('#gform_' + id + ' .ftriple').wrapAll('<div class="f_wrap f_wrap--3 gfield gfield--width-full"></div>');
         jQuery('#gform_' + id + ' .fdouble').wrapAll('<div class="f_wrap f_wrap--2 gfield gfield--width-full"></div>');

			if (id == 1) {
				enquiry_hidden_ids.push('input_' + id + '_15');
			}
      }
   }

	function setEnquirySelect(value) {
		var options = Array.from(jQuery('#enquiry_about option'));
		let optionIndex = options.findIndex(i => i.innerText == value);
		if (optionIndex) {
			jQuery('#enquiry_about option')[0].removeAttribute('selected');
			options[optionIndex].setAttribute('selected', true);
		}
	}

	let check_error = jQuery('.gform_validation_error');
	if (check_error.length > 0) {
		check_error = jQuery(check_error).parent();

		jQuery('.enquiry_about__asterix').before(jQuery('.gform_validation_errors'));
		jQuery('.gform_validation_errors').wrap('<div class="gform_wrapper gravity-theme"></div>');

		if (check_error.hasClass('contact_page_forms__form--sales')) {
			jQuery('.contact_page_forms__form--sales').show();
			setEnquirySelect(jQuery('input#input_1_15').val());
		}

		if (check_error.hasClass('contact_page_forms__form--financial')) {
			jQuery('.contact_page_forms__form--financial').show();
			setEnquirySelect('Financial enquiry');
		}

		if (check_error.hasClass('contact_page_forms__form--other')) {
			jQuery('.contact_page_forms__form--other').show();
			setEnquirySelect('Other');
		}
	}

	let forms = jQuery('.contact_page_forms__form').find('.gform_confirmation_wrapper');
	if (forms.length > 0) {
		jQuery(forms[0]).parent().show();
		jQuery('.contact_page_forms__wrap').addClass('contact_page_forms__wrap--submitted');
	}

	NiceSelect.bind(document.getElementById('enquiry_about'), {searchable: false});

   function getForm(val) {
		var sales = ['ADC development and manufacturing', 'API development and manufacturing', 'Sales enquiry', 'Peptides development and manufacturing'];
		var form = {name: '', id: 0};

		if (sales.includes(val)) {
			form.name = 'sales';
		}

		if (val == 'Financial enquiry') {
			form.name = 'financial';
		}

		if (val == 'Other') {
			form.name = 'other';
		}
		
		form.id = gforms[form.name];

		return form
	}

	let upload_label = jQuery('#field_9_6 .gfield_label');
	if (upload_label) {
		let upload_input = jQuery('input#input_9_6');
		let upload_p = document.createElement("p");
		let desc = jQuery('#gfield_description_9_6');
		desc.hide();
		let max = jQuery('#field_9_6 .gform_fileupload_rules');
		let str = max.text().replace('Max. file size: ', '').replace('.', '').replace(' ', '').toLowerCase();
		max.text('(max file size ' + str + ')');
		upload_p.classList.add('gfield_label');
		upload_p.innerText = 'Would you like to attach invoices or supporting documentation?';
		upload_label.before(upload_p);
		upload_label.text('Attach a file').removeClass('gfield_label gform-field-label').addClass('upload_label');
		upload_input.after(upload_label);
		upload_input.attr('hidden', true);

		upload_input[0].addEventListener('change', function(){
			upload_label.text(this.files[0].name)
		});
	}

	jQuery('#enquiry_about').on('change', (e) => {
		const val = e.target.value;
		var form = getForm(val);

		// Update hidden field
		if (form.id == 1) {
			jQuery('#input_' + form.id + '_15').val(val);
		}

		jQuery('.contact_page_forms__form').hide();

		jQuery('.contact_page_forms__form--' + form.name).show();
	});
}