import { initCtaFunctions } from './common/ctaFunctions';
import { initNav } from './common/nav';
import { initSelect2 } from './modules/select2';
import { initHeroSlider } from './modules/heroSlider';
import { initLoadMore } from './modules/loadMore';
import { initModSlider } from './modules/modSlider';
import { initMolecule } from './modules/moleculeRange';
import { initTabbedContent } from './modules/tabbedContent';
import { initAccordion } from './modules/accordion';
import { initContact } from './common/contact';
import { initHome, initHomeVideo } from './common/home';

initHomeVideo();

// jQuery doc ready
$(function () {
	// Set up the form toggle
	initCtaFunctions();
	initNav();
	initLoadMore();

	// Initilise modules
	initSelect2();
	initHeroSlider();
	initModSlider();
	initMolecule();
	initTabbedContent();
	initAccordion();

	// Contact
	if (jQuery('.contact_page_forms').length > 0) {
		initContact();
	}

	initHome();
 
});
