export function initAccordion() {

    $(".accordion_trigger a").each(function () {


        const $this = $(this);
        const $all_p = $(".accordion_trigger");
        const $all_t = $(".accordion_trigger + .modular");
        const $parent = $this.parent(".modular");
        const $target = $parent.next(".modular");
        const id = $parent[0].id;

        $all_p.removeClass("open").addClass("closed");
        $all_t.slideUp(0);

        $this.on("click", function (e) {


            e.preventDefault();

            // $scrollto = $id;
            console.log(id);

            if ($parent.hasClass("open")) {

                $parent.removeClass("open").addClass("closed");
                $target.slideUp(200);

            } else {

                $all_p.removeClass("open").addClass("closed");
                $all_t.slideUp(200);
                $parent.removeClass("closed").addClass("open");
                $target.slideDown(200);

            }
            // setTimeout(() => {
            //     var scrollDiv = document.getElementById(id).offsetTop;
            //     window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
            //
            // }, 250);

            setTimeout(function () {
                $('html, body').animate({
                    scrollTop: $this.offset().top - $('#h_main').outerHeight()
                }, 300);
            }, 500);


        });

    });

}
