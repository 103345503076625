export function initHeroSlider() {
	if ($('.slick_hero')[0]) {
		$('#hero_main .hero_main_slider').slick();
	}
	if ($('.hero_swiper')[0]) {
		var swiperData = $('.hero_main_slider').data('swiper');
		var params = {
			//speed: swiperData.speed,
			speed: 1500,
			loop: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: true,
			},
		};
		if (swiperData.arrows) {
			$.extend(params, {
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			});
		}

		if (swiperData.dots) {
			$.extend(params, {
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
			});
		}

		if (swiperData.fade) {
			$.extend(params, {
				effect: 'fade',
				fadeEffect: {
					crossFade: true,
				},
			});
		}

		if (swiperData.autoplay) {
			$.extend(params, {
				autoplay: {
					delay: swiperData.autoplaySpeed,
					disableOnInteraction: true,
				},
			});
		}

		var heroSlider = new Swiper('.hero_main_slider', params);
	}
}
