export function initNav() {

    var navigation = new Navigation(document.getElementById("navigation"));

    let $test = $('.navigation-item.has-submenu');


    $test.on("mouseenter", function (e) {
        $('div#menu-overlay').addClass('active');
    });

    $test.on("mouseleave", function (e) {
        $('div#menu-overlay').removeClass('active');
    });

}