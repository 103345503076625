// ADJUST POSITION TO SHOW ALL OF FORM
export function ctaSlideAdjust() {
	
	// CHECK THE WHOLE FORM IS VISIBLE
	const ctaForm = $("#contact_cta_marker");
	const formTop = ctaForm.offset().top;
	const formBottom = formTop + ctaForm.outerHeight();
	const viewportTop = $(window).scrollTop();
	const viewportBottom = viewportTop + $(window).height();
	
	if (formBottom > viewportTop && formTop < viewportBottom) {
		
		// DO NOTHING
	
	} else {
		
		$("html, body").animate({
			scrollTop: viewportTop + (formBottom - viewportBottom),
		},200);
		
	}
	
}

// SET UP THE FORM TOGGLE
export function initCtaFunctions() {
	
	$("a.contact_cta_toggle").on("click", function (e) {
		
		e.preventDefault();
		
		const $thisParent = $(this).closest(".contact_cta_form");
		const $thisContent = $thisParent.find(".contact_form_cta_contents");
		
		if ($thisParent.hasClass("active")) {
			
			$thisParent.removeClass("active");
			$thisContent.slideUp(200);
			
		} else {
			
			$thisParent.addClass("active");
			$thisContent.slideDown(200, function () {
				
				ctaSlideAdjust();
				
			});
			
		}
		
	});
}
