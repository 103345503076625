import { transform } from "lodash-es";


export function initHome() {

	function throttle(func, wait) {
		let waiting = false;
		return function () {
			 if (waiting) {
				  return;
			 }
  
			 waiting = true;
			 setTimeout(() => {
				  func.apply(this, arguments);
				  waiting = false;
			 }, wait);
		};
  }

	var $window = $(window);
	var foot_offset = $("body").innerHeight() - jQuery('#main_footer').innerHeight() - $(window).height();

   // Global CTA transition
	$(document).on('scroll', throttle(function() {
		if ($window.scrollTop() >= 500 && $window.scrollTop() <= foot_offset) {
			jQuery('#global_cta').addClass('show');
		} else {
			jQuery('#global_cta').removeClass('show');
		}
	}, 250));

	var lf_cycles_slider = new Swiper('.lf_cycles .swiper', {
      slidesPerView: 1,
      spaceBetween: 40,
      navigation: {
         nextEl: '.lf_cycles .lf_cycles_next',
         prevEl: '.lf_cycles .lf_cycles_prev',
      },
      pagination: {
         el: '.lf_cycles .lf_cycles__pagi',
         type: 'custom',
         renderCustom: function(swiper, current, total) {
            return 'Phase ' + current + ' of ' + total;
         }
      },
      breakpoints: {
         601: {
            slidesPerView: 2,
            spaceBetween: 30,
         },
         768: {
            slidesPerView: 3,
            spaceBetween: 30,
         }
      }
   });

   var khbc_slider = new Swiper('.khbc .swiper', {
      slidesPerView: 1,
      spaceBetween: 40,
      navigation: {
         nextEl: '.khbc .lf_cycles_next',
         prevEl: '.khbc .lf_cycles_prev',
      },
      pagination: {
         el: '.khbc .swiper-pagination',
         clickable: true
      },
      breakpoints: {
         601: {
            slidesPerView: 2,
            spaceBetween: 30,
         },
         768: {
            slidesPerView: 3,
            spaceBetween: 40,
         }
      }
   });

   jQuery('.pin_trigger').click(function() {
      var target = jQuery(this).parents('.pin').data('target');
      jQuery('.of_globe__info').removeClass('active');
      jQuery('[data-info="' + target + '"]').addClass('active');
   });

   jQuery(document).mouseup(function(e) {
      var container = jQuery('.pin');

      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0) {
         jQuery('.of_globe__info').removeClass('active');
      }
   });

   jQuery('.of_globe__title').click(function() {
      jQuery(this).parent().toggleClass('of_globe--open');
   });

   // hex_g
   var hexes = Array.from(jQuery('.hex_g'));
   if (hexes.length > 0) {
      const options = {
         root: null,
         rootMargin: "0px",
         threshold: 1.0,
      };
   
      const callback = (entries, observer) => {
         entries.forEach((entry) => {
            if (entry.isIntersecting) {
               jQuery(entry.target).addClass('hex_g--animate');
               observer.unobserve(entry.target);
            }
         });
      };
   
      const observer = new IntersectionObserver(callback, options);
   
      hexes.map(i => observer.observe(i));
   }

   if (jQuery('.of_globe .pin').length > 0) {
      gsap.set('.of_globe .pin[data-target="ringaskiddy"]', {x: '+=5', y: '+=10'});
      gsap.to('.of_globe .pin', {duration: 1, scale: 1.04, transformOrigin: '50% 100%', repeat: -1, yoyo: true});
   }
}

export function initHomeVideo() {
   var video = document.querySelector('video.search_banner__vid');
   if (video && jQuery(document).width() >= 600) {
      var sources = document.querySelectorAll('video.search_banner__vid source');
      for (var i = 0; i < sources.length; i++) {
        sources[i].setAttribute('src', sources[i].getAttribute('data-src'));
      }
      video.load();
   }
}