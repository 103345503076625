export function initTabbedContent() {
	
	$(".modular.tabbed_content").each(function () {
		
		const $module	=	$(this);
		const $select	=	$module.find("#tab_select");
		const $tabs		=	$module.find("#tab_titles");
		const $content	=	$module.find("#tab_content");
		
		$tabs.find("a").on("click", function (e) {
			
			e.preventDefault();
			const $tab = $(this);
			const $target = $content.children("#" + $tab.attr("data-target"));
			
			$module.find("#tab_titles > a").removeClass("active");
			$tab.addClass("active");
			
			$module.find("#tab_content > div").removeClass("active");
			$target.addClass("active");
			
		});
		
		$select.on("change", function (e) {
			
			e.preventDefault();
			const $target = $content.children("#" + $select.val());
			
			$module.find("#tab_content > div").removeClass("active");
			$target.addClass("active");
		
		});
		
	});
	
}
