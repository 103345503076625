import { initQueryParams } from "./queryParams";


export function initMolecule() {
    let $parent = $("#range-tax");
    let myInput = 'moleculeRange';

    if(!document.getElementById(myInput)){
        return false;
    }

    function uiChange(elm,current,value, termid=null){
        console.log(value);
        
        // if(null != termid){
        //     $(`[data-description]`).fadeOut( 500);
        //     setTimeout(() => {
        //         $(`[data-description='${termid}']`).fadeIn();
        //     }, 500);
        // }
        
        
        
        $parent.find('.molecule-tax').removeClass('mole-active mole-sib');
        $parent.find(`[data-range='${current}']`).parent().delay(200).addClass('mole-active');
        $parent.find(`[data-range='${current}']`).parent().delay(200).prev().addClass('mole-sib');
        $parent.find(`[data-range='${current}']`).parent().delay(200).next().addClass('mole-sib');
        elm.style.background = 'linear-gradient(to right, #78BE20 0%, #78BE20 ' + value + '%, #003057 ' + value + '%, #003057 100%)'
        // var data = termid;

        
       


        

        // fetch('/wp-json/wpc/v1/molecule/'+termid+'/').then(response => {

        //     return response.json();
      
        //   }).then(jsonResponse => {
      
        //     console.log({ jsonResponse });


            
      
        //   });
    }
    
    document.getElementById(myInput).oninput = function() {
        var value = (this.value-this.min)/(this.max-this.min)*100
        var current = this.value;
        // var termid = $(this).attr('data-term')
        var termid = $parent.find(`[data-range='${current}']`).attr('data-term');
        

        $('#moleculeTermID').val(termid);
        window.history.replaceState('', '', initQueryParams(window.location.href, "stage", termid));
        // console.log('term id='+termid);
        $(`[data-description]`).fadeOut( 500);
        setTimeout(() => {
            $(`[data-description]`).fadeOut( 1);
            $(`[data-description='${termid}']`).fadeIn();
            
        }, 500);

        uiChange(this,current,value, termid);
        
      };

      $('.molecule-tax a').on("click", function (e) {
        e.preventDefault();
        var current = $(this).attr('data-range')
        var flag = true;
        
        var $input = document.getElementById(myInput);
        var value = (current-$input.min)/($input.max-$input.min)*100
        var termid = $parent.find(`[data-range='${current}']`).attr('data-term')
        // alert(termid);
        $(`[data-description]`).fadeOut( 500);
        setTimeout(() => {
            $(`[data-description]`).fadeOut( 1);
            $(`[data-description='${termid}']`).fadeIn();
            
        }, 500);
        $('#moleculeTermID').val(termid).trigger('change');
        // var termid = $(this).attr('data-term')

        // console.log(termid);

        
        // var newURL = initQueryParams(window.location.href, 'locId', 'newLoc');
        // newURL = initQueryParams(newURL, 'resId', 'newResId');
        
        
        $input.value = current;
        // $(this).attr('data',r)
        window.history.replaceState('', '', initQueryParams(window.location.href, "stage", termid));

        uiChange($input,current,value);
        // console.log(value)


      });
    // var navigation = new Navigation(document.getElementById("navigation"));

    // let $test = $('.navigation-item.has-submenu');


    // $test.on("mouseenter", function (e) {
    //     $('div#menu-overlay').addClass('active');
    // });

    // $test.on("mouseleave", function (e) {
    //     $('div#menu-overlay').removeClass('active');
    // });
    
}
